.media-gallery {
  @apply grid min-h-screen w-full;

   overscroll-behavior: contain;

  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 0em 0px;
  grid-auto-flow: row;
  grid-template-areas: 'items'
  ;
}

.gallery-items {
  @apply relative z-10;

  grid-area: items;
}

.post-content {
  @apply bg-gray-800;

  grid-area: content;
}

.gallery {
  @apply flex fixed overflow-scroll top-0 left-0 z-50 h-screen w-full bg-black/80 p-4 lg:p-16 items-center;

  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scroll-padding: 1rem;
  padding-top: env(safe-area-inset-top, 4rem);
  padding-bottom: env(safe-area-inset-bottom, 4rem);
}

.media-gallery .media {
  @apply h-full w-full overflow-y-scroll relative flex;

  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.gallery-icon {
  @apply absolute z-10 bg-black/90;
}

.gallery-close {
  @apply left-5 top-5;
}

.gallery-back,
.gallery-forward {
  @apply top-1/2 -translate-y-1/2;
}

.gallery-back {
  @apply left-5;
}

.gallery-forward {
  @apply right-5;
}

.gallery__item {
  @apply w-full h-full relative;

  flex-shrink: 0;
  scroll-snap-align: start;
}

.gallery__image {
  @apply object-contain;
}
