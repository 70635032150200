:root {
    --tw-border-opacity: 1;
    --spinner-color: #E02020;
    --spinner-border-color: var(--spinner-color, rgba(224, 32, 32, var(--tw-border-opacity)));
}

/* Make clicks pass-through */

#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    @apply fixed z-[100] top-0 left-0 w-full h-0.5;

    background-color:  var(--spinner-color);
}

/* Fancy blur effect */

#nprogress .peg {
    @apply block absolute right-0 w-28 h-full opacity-100 transform rotate-3 -translate-y-1;
    box-shadow: 0 0 10px var(--spinner-border-color), 0 0 5px var(--spinner-border-color);
}

/* Remove these to get rid of the spinner */

#nprogress .spinner {
    @apply block fixed z-[100] top-4 right-4;
}

#nprogress .spinner-icon {
    @apply w-5 h-5 border-2 border-[transparent] rounded-full;
    box-sizing: border-box;
    border-top-color: var(--spinner-border-color);
    border-left-color: var(--spinner-border-color);
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner, .nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
