.notification {
  @apply flex text-gray-500 cursor-pointer focus:outline-none focus-within:bg-gray-100 hover:bg-gray-100 p-4 justify-center text-left;
}

.notification > .container {
  @apply flex -mx-4 w-full;
}

.notification > .container .image-wrapper {
  @apply relative px-4 w-16 h-16 flex;
}

.notification .image-wrapper:focus > div {
  @apply ring-2;
}

.image-wrapper > .btn > div {
  @apply rounded-full w-16 h-16 custom-ease hover:ring-2 ring-offset-2 ring-red-500;
}

.notification > .container .text-wrapper {
  @apply px-4 w-full space-y-2;
}

.notification > .container .text-wrapper .btn {
  @apply text-left;
}

.text-wrapper .user-link {
  @apply font-semibold mr-1;
}

.text-wrapper .user-link,
.text-wrapper .message-link {
  @apply text-gray-900 underline custom-ease;
  text-decoration-color: rgb(228, 228, 231);
}

.text-wrapper .user-link:hover,
.text-wrapper .message-link:hover,
.text-wrapper .user-link:focus,
.text-wrapper .message-link:focus {
  text-decoration-color: rgb(239, 68, 68);
}

.text-wrapper .message-link {
  @apply line-clamp-2;
}
