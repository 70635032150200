.btn {
  @apply custom-ease disabled:cursor-not-allowed disabled:opacity-50;
}

.btn-icon {
  @apply items-center justify-center;
}

.btn-icon.m-icon:not(.w-label, [disabled]) {
  @apply hover:bg-gray-200 rounded-full;
}

.btn-icon.w-label {
  @apply px-2;
}

.m-icon.w-label {
  @apply flex-col;
}

.m-icon .icn {
  @apply rounded-full w-8 h-8 custom-ease flex items-center justify-center;
}

.m-icon:focus.w-label:not([disabled]) .icn:not(.fresh, .like, .insight),
.m-icon:hover.w-label:not([disabled]) .icn:not(.fresh, .like, .insight) {
  @apply bg-gray-200;
}

.btn-light {
  @apply focus:border-2 border-blue-400;
}

.btn-light:not(.modal-close) {
  @apply text-white hover:text-black focus:text-black;
}

.btn-icon:not(.logo-link) {
  @apply flex;
}

.btn-icon:not(.logo-link, .login-btn, .w-label, .badge-icon, .source-link) {
  @apply flex rounded-full w-8 h-8 text-xl lg:text-base;
}

.btn-icon.icon-focused:not(.login-btn, .m-icon.w-label, .logo-link, .filter-close, .filter-search, .likes, .insights, .fresh, .source-link) {
  @apply focus:bg-gray-200;
}

.btn.base:not(.clear) {
  @apply justify-center items-center inline-flex focus:outline-none focus:ring-2 hover:scale-[0.99];
}

.btn.contained:not(.clear) {
  @apply text-center text-white rounded-lg;
}

.btn.contained:not(.color) {
  @apply bg-black;
}

.btn.contained.danger {
  @apply bg-red-600;
}

.btn.outlined:not(.clear) {
  @apply border-2 rounded-lg;
}

.btn.clear {
  @apply focus:outline-none;
}

.btn.outlined.medium:not(.clear) {
  @apply py-2;
}

.btn.small:not(.clear) {
  @apply text-sm md:text-base p-3 md:py-2;
}

.btn.medium:not(.clear, .clear) {
  @apply px-4 py-2 text-lg;
}

.btn.large:not(.clear) {
  @apply px-4 py-2 text-xl;
}

.btn-green {
  @apply px-6 py-3 rounded bg-green-700 text-white text-sm font-bold whitespace-nowrap hover:bg-yellow-500 focus:bg-yellow-500;
}

.btn-spinner,
.btn-spinner:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
}

.btn-spinner {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid white;
  border-right: 0.2em solid white;
  border-bottom: 0.2em solid white;
  border-left: 0.2em solid transparent;
  transform: translateZ(0);
  animation: spinning 1s infinite linear;
}

.login-btn {
  @apply flex-grow rounded focus:ring-2 hover:ring-2 min-h-[3rem] border shadow-sm w-12 h-12;
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.back-button {
  @apply hover:bg-gray-200 rounded-full focus:outline-none focus:bg-gray-200;
}
