.home-item {
  @apply w-full lg:w-1/2 flex;
}

.home-item.image-wrapper {
  @apply place-content-center place-items-center bg-gray-800;
}

.home-item.image-wrapper {
  @apply py-16 lg:py-0;
}

.home-item.image-wrapper > .logo {
  @apply w-52 h-52 z-10 relative;
}

.home-item.image-wrapper > .hero-image {
  @apply absolute z-0 w-full h-full top-0 overflow-hidden;
}

.home-item.image-wrapper > .hero-image > .trending-grid {
  @apply flex flex-wrap transform skew-y-6 -skew-x-12 -translate-x-44 scale-150 lg:scale-110 left-0 -m-1;
}

.trending-grid .entity-card {
  @apply w-20 lg:even:w-40 lg:odd:w-1/5 rounded-none p-1;
}

.trending-grid .entity-card:nth-child(3),
.trending-grid .entity-card:nth-child(8),
.trending-grid .entity-card:nth-child(13),
.trending-grid .entity-card:nth-child(28),
.trending-grid .entity-card:nth-child(23) {
  @apply w-32 lg:w-96;
}

.trending-grid .entity-card .image_wrapper {
  @apply w-full h-32 lg:h-60 ring-0 rounded-none overflow-hidden;
}

.trending-grid .entity-card .image_wrapper img {
  @apply rounded-none;
}

.trending-grid .entity-card .content-wrapper {
  @apply p-0;
}

.image-wrapper .overlay {
  @apply absolute w-full h-full bg-black/40 z-10;
}

.image-wrapper img {
  @apply object-cover z-0;
}

.home-item.text-wrapper {
  @apply block space-y-16 lg:space-y-0 lg:overflow-y-scroll lg:max-h-[95vh] z-10 bg-white;
}

.text-wrapper .content {
  @apply space-y-4;
}

.text-wrapper .content > .logo {
  @apply w-16 h-16;
}

.text-wrapper .content > .headings {
  @apply font-bold space-y-16;
}

.text-wrapper .content > .headings > h1 {
  @apply text-4xl;
}

.text-wrapper .content > .headings > h2 {
  @apply text-2xl;
}

.item-hero {
  @apply lg:min-h-screen flex lg:place-items-center px-4 py-8 md:p-10 lg:p-16;
}

.item-hero .content {
  @apply w-full;
}

.about {
  @apply bg-warmGray-100 px-4 py-8 md:p-10 lg:p-16 max-w-full;
}

.about > .content {
  @apply prose;
}

.about > .content .socials {
  @apply text-black;
}

.about > .content .socials,
.about > .content .socials:hover {
  box-shadow: none;
}


.about > .content > .logo {
  @apply w-20 h-20 mb-10;
}
