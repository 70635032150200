.app {
  @apply grid grid-cols-1 min-h-screen;
  grid-area: content;
  grid-template-rows: auto auto auto 6fr auto auto minmax(60px, 80px);
  gap: 0px 0px;
  grid-template-areas:
    'hero'
    'trending'
    'tabs'
    'feed'
    'discussion'
    'search'
    'nav';
}

.app.hide-nav {
  grid-template-rows: auto auto auto 6fr auto auto;
  grid-template-areas:
    'hero'
    'trending'
    'tabs'
    'feed'
    'discussion'
    'search';
}

@screen lg {
  .app,
  .app.hide-nav {
    grid-template-columns: 1fr minmax(0, 4fr) minmax(0, 3fr) 1fr;
    grid-template-rows: auto auto auto auto auto 1fr 1fr;
    grid-auto-flow: row;
    grid-template-areas:
      'nav nav nav nav'
      'hero hero hero hero'
      'trending trending trending trending'
      '. search . .'
      '. tabs sidebar .'
      '. feed sidebar .'
      '. feed discussion discussion';
  }

  .app.hide-aside {
    grid-template-columns: 1fr max(1024px) 1fr;
    grid-template-rows: auto auto auto auto auto 1fr 1fr;
    grid-auto-flow: row;
    grid-template-areas:
      'nav nav nav'
      'hero hero hero'
      'trending trending trending'
      '. search .'
      '. tabs discussion'
      '. feed discussion'
      '. feed discussion';
  }
}

.page-notifications {
  @apply min-h-screen;
}

.trending {
  @apply overflow-y-hidden;

  grid-area: trending;
}

.hero {
  grid-area: hero;
}

.feed {
  @apply py-8 lg:border overflow-y-scroll;

  grid-area: feed;
}

.discussion {
  @apply lg:pl-20 lg:pr-8 xl:px-8 fixed lg:right-0 xl:right-20 z-40 flex items-end w-full lg:max-w-lg;

  grid-area: discussion;
}

.sidebar {
  @apply lg:pl-8 lg:pt-4 lg:pr-0 hidden lg:block lg:relative lg:bottom-0 lg:pb-8;

  grid-area: sidebar;
}

.sidebar > .section {
  @apply lg:p-0 lg:block lg:sticky lg:top-24 bottom-0;
}

.entity-wrapper {
  @apply relative min-h-[100px] z-10 pt-8 lg:pt-0;
}

.tabs {
  @apply sticky left-0 top-0 lg:top-[4.5rem] lg:py-4 w-full bg-gray-900 lg:bg-white text-white lg:text-gray-600 text-center z-30 flex flex-col place-items-center shadow-lg lg:shadow-none custom-ease lg:my-4 lg:border-b space-y-4;

  grid-area: tabs;
}

.tabs > .wrapper {
  @apply flex w-full -mx-8 justify-around;
}

.tab {
  @apply w-auto;
}

.tab > .wrapper.underlined {
  @apply lg:text-black border-b-2 border-white lg:ring-red-500 lg:focus:ring-2 lg:ring-2;
}

.tab > .wrapper {
  @apply flex place-items-stretch place-content-center lg:bg-gray-100 border-gray-400 focus:outline-none focus-within:border-b-2 focus:border-b-2 lg:focus:border-b-0 lg:rounded-full uppercase lg:focus:rounded-none w-[max-content] p-2 lg:py-2 lg:px-4;
}

.tab > .wrapper:not(.underlined) {
  @apply hover:border-b-2 lg:hover:border-b-0 focus:border-b-2 lg:focus:border-b-0;
}

.tab .btn {
  @apply lg:focus:rounded-full lg:focus:outline-none py-3 lg:ring-black lg:ring-2 lg:hover:ring-red-500 lg:focus:ring-red-500;
}

.page-content {
  @apply w-full;
  grid-area: feed;
}

.page-content > .page-wrapper {
  @apply p-4 md:px-0 md:py-8;
}

.page-wrapper:has(.about) {
  @apply bg-warmGray-100;
}

.page-wrapper > * > .content,
.page-wrapper > .content {
  @apply prose mx-auto;
}

.page-wrapper .about {
  @apply p-0 bg-white;
}

.page-wrapper .card-group {
  @apply max-w-screen-md mx-auto;
}

.page-wrapper .base-card > .wrapper {
  @apply shadow-none border;
}

.app-home {
  @apply min-h-screen flex;
}

.app-home > .wrapper {
  @apply flex-grow min-h-screen flex flex-col;
}

.app-home > .wrapper .main {
  @apply flex flex-1 flex-wrap;
}

.scroll-snap {
  scroll-margin-top: 30rem;
  scroll-snap-margin-top: 30rem;
  --webkit-scroll-snap-margin-top: 30rem;
}

.alerts {
  @apply max-w-screen-md fixed bottom-24 z-50 left-1/2 transform -translate-x-1/2;
}

.alert-wrappe {
  @apply flex flex-col;
}

.app-message {
  @apply shadow-md rounded px-3 py-2 transition-all mt-2 bg-yellow-500 text-yellow-900;
}

.app-message > .btn {
  @apply w-[max-content] max-w-xs;
}

.app-message.success {
  @apply bg-green-300 text-gray-800 flex items-center;
}

.app-message.error {
  @apply bg-red-500 text-white;
}


.app-message .alert-wrapper {
  @apply flex items-center text-left -mx-2;
}

.app-message .alert-wrapper > * {
  @apply px-2;
}

.alert-wrapper > * > .icon {
  @apply w-6 h-6;
}
