.message {
  @apply p-4 lg:p-6 focus:outline-none text-left w-full;
}

.message:not(.parent-thread) {
  @apply border-b last:border-b-0;
}

.message:not(.thread, .parent-thread, .main-thread) {
  @apply first:border-t;
}

.message.thread {
  @apply relative;
}

.message.thread:not(.main-thread) .thread-line {
  @apply w-1 h-full bg-red-900;
}

.message.thread:not(.parent-thread):nth-last-child(1) .thread-line {
  @apply hidden;
}

.msg-wrapper {
  @apply grid custom-ease;

  scroll-margin-top: 20rem;
  scroll-snap-margin-top: 20rem;
  --webkit-scroll-snap-margin-top: 20rem;
  grid-template-columns: max(48px) 1fr 1fr;
  grid-template-rows: max-content max-content max-content max-content;
  gap: 0em 1em;
  grid-template-areas:
    'info info info'
    'profile details menu'
    'profile content content'
    'profile actions actions';
}

.message.main-thread .msg-wrapper {
  grid-template-rows: max-content max-content max-content max-content;
  grid-template-areas:
    'info info info info'
    'profile details details menu'
    'content content content content'
    'actions actions actions actions';
}

.message:not(.unavailable, .main-thread, .main-thread.child) {
  @apply cursor-pointer focus:bg-gray-100 lg:hover:first:rounded-tl-lg focus:outline-none focus-within:bg-gray-100 lg:hover:first:rounded-tr-lg custom-ease;
}

.msg-wrapper .profile-wrapper {
  height: fit-content;
}

.msg-wrapper .profile-pic {
  @apply flex flex-wrap justify-center;
  grid-area: profile;
  z-index: 0;
}

.msg-wrapper > .info {
  @apply space-y-2 text-sm text-gray-600;

  grid-area: info;
}

.message .msg-wrapper > .info {
  @apply mb-4;
}

.msg-wrapper > .profile-details {
  @apply flex pr-5 cursor-default;
  grid-area: details;
}

.msg-wrapper > .menu-container {
  @apply relative flex items-center justify-end;

  grid-area: menu;
}

.msg-wrapper > .menu-container section > .wrapper {
  @apply custom-ease hover:bg-gray-200 focus:bg-gray-200 focus:outline-none rounded-full;
}

.menu-container section > .wrapper > .icon {
  @apply w-7 h-7;
}

.msg-wrapper > .menu-container .menu-content {
  @apply top-7 w-max;
}

.msg-wrapper > .msg-content {
  @apply my-4 md:mt-0 lg:mb-6;

  grid-area: content;
}

.unavailable .info-content {
  @apply border bg-gray-100 w-full p-4 place-content-center place-items-center rounded-lg;
}

.highlighted .msg-wrapper {
  scroll-margin-top: 5rem;
  scroll-snap-margin-top: 5rem;
  --webkit-scroll-snap-margin-top: 5rem;
}

/* .highlighted:not(.main-thread.parent) .msg-wrapper {
  @apply ring-2 ring-offset-4 ring-red-500 rounded-lg custom-ease;
} */

.main-thread .msg-header > .msg-container {
  @apply flex-wrap space-y-4;
}

.main-thread {
  @apply cursor-default;
}

.main-thread .msg {
  @apply text-lg;
}

.msg-content {
  @apply cursor-text whitespace-pre-wrap space-y-4 overflow-hidden;
}

.decorated-link {
  @apply overflow-hidden whitespace-nowrap text-blue-500;

  text-overflow: ellipsis;
}

.decorated-link a {
  @apply focus:outline-none focus:ring-2;
}

.profile-details > .btn .username,
.profile-details > .time-stamp,
.msg-wrapper .link-styled {
  @apply underline custom-ease;
  text-decoration-color: rgb(212, 212, 216);
}

.profile-details > .user-link {
  @apply md:flex items-start mr-2;
}

.profile-details > .time-stamp {
  @apply focus:underline hover:underline;
}

.profile-details > .btn .username {
  @apply line-clamp-1 mr-2 font-bold;
}

.profile-details > .btn .userat {
  @apply text-red-900;
}
.msg-timestamps {
  @apply w-full pt-2;
}

.msg-timestamps:hover > .items,
.msg-timestamps:focus > .items {
  text-decoration: underline;
}

.msg-timestamps > .items {
  @apply custom-ease;
}

.msg-timestamps > .spacer {
  @apply mx-2;
}

.room-link {
  @apply cursor-default;
}

.msg-timestamps:hover > .items,
.msg-timestamps:focus > .items,
.profile-details > .time-stamp:hover,
.profile-details > .time-stamp:focus,
.profile-details > .btn:focus .username,
.profile-details > .btn:hover .username,
.room-link > .btn:hover .decorated-link a,
.room-link > .btn:hover .link-styled,
.room-link > .btn:focus .decorated-link a,
.room-link > .btn:focus .link-styled {
  text-decoration-color: rgb(239, 68, 68);
}

.profile-pic .btn > div {
  @apply rounded-full custom-ease ring-1 ring-gray-300;
}

.profile-pic .btn:focus > div,
.profile-pic .btn:hover > div {
  @apply ring-2 ring-red-500 ring-offset-2;
}

.msg-wrapper .action {
  @apply focus:outline-none flex items-center space-x-2;
}

.msg-wrapper .media {
  @apply w-full grid border rounded-lg relative h-36 lg:h-64 overflow-hidden;
}

.msg-wrapper .msg-stats {
  @apply w-full flex flex-wrap text-sm text-gray-700 border-b pb-2;
}

.msg-wrapper .msg-stats .stat {
  @apply text-left w-full py-2 border-b custom-ease hover:border-red-500 focus:border-red-500;
}
