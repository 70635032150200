.card:not(.noInteraction) {
  @apply cursor-pointer;
}

.card {
  @apply last:mb-0 w-full flex items-stretch overflow-hidden shadow transform text-left  ring-1 ring-gray-200 rounded-lg custom-ease focus:outline-none hover:ring-gray-500 focus:ring-gray-500;
}

.card.room-card > .wrapper {
  @apply p-0 overflow-hidden rounded-lg;
}

.card.room-card .content {
  @apply p-4 text-left;
}

.room-card .title {
  @apply focus:outline-none w-full font-bold text-lg;

  text-overflow: ellipsis;
}

.room-card .descriptions {
  @apply text-gray-600 text-xs font-normal line-clamp-1;
}

.room-card .menu-container {
  @apply absolute right-2 top-2 z-10;
}

.room-card .creator-pic {
  @apply object-cover;
}

.room-card .menu-container > .wrapper {
  @apply bg-black/60 text-white hover:ring-2 rounded-full shadow focus:outline-none focus:ring-2 ring-red-500 custom-ease;
}


.room-card .content-wrapper {
  @apply space-y-2;
}

.card > .wrapper {
  @apply w-full bg-white p-4 flex flex-wrap space-y-4;
}

.card .cta {
  @apply flex-grow self-end;
}

.card .content {
  @apply w-full space-y-4;
}

.card .content .description {
  @apply text-gray-600 text-xs overflow-hidden whitespace-nowrap line-clamp-1;
}

.card .content .names {
  @apply text-gray-600 text-xs;
}

.card .content .buttons {
  @apply flex justify-between;
}

.card .buttons .title {
  @apply custom-ease font-bold text-xl overflow-hidden hover:border-b-2;
}

.entity-card {
  @apply focus:outline-none;

  scroll-snap-align: start;
}

.entity-card .content-wrapper {
  @apply px-4 space-y-2 rounded-lg;
}

.entity-card .image_wrapper {
  @apply relative h-60 w-40 ring-2 ring-gray-800 hover:ring-offset-4 ring-offset-gray-800 hover:ring-red-500 rounded-lg custom-ease overflow-hidden;
}

.entity-card:focus .image_wrapper {
  @apply ring-red-500 ring-offset-4;
}

.entity-card .image_wrapper img {
  @apply rounded-lg;
}

.entity-image {
  @apply w-full h-full object-cover;
}

.entity-card .entity-content {
  @apply text-sm w-40 line-clamp-2;
}

.entity-card .entity-content p {
  @apply font-semibold;
}

.card-group {
  @apply flex flex-wrap -my-2 md:-m-2 items-stretch;
}

.base-card {
  @apply w-full py-2 md:p-2 flex;
}

.base-card.half {
  @apply md:w-1/2;
}

.base-card > .wrapper {
  @apply flex-grow bg-white shadow p-4 rounded-lg border;
}

.base-card .content {
  @apply space-y-4;
}

.base-card .content .heading {
  @apply flex items-center -mx-1;
}

.base-card .heading > h3 {
  @apply text-lg font-semibold;
}

.base-card p {
  @apply text-warmGray-600;
}

.base-card .heading > * {
  @apply px-1;
}

.base-card .heading .icon {
  @apply w-6 h-6;
}

.card-profile {
  @apply w-12 h-12 relative;
}

.card-profile > .btn > div {
  @apply ring-1 ring-gray-200 rounded-full custom-ease bg-gray-200;
}

.card-profile > .btn:hover > div,
.card-profile > .btn:focus > div {
  @apply ring-2 ring-red-500;
}

.e-image {
  @apply min-w-[2rem] w-7 h-10 relative border rounded-sm overflow-hidden;
}

.listener {
  @apply font-semibold rounded-full bg-gray-300 w-8 h-8 flex items-center justify-center text-center border-white ring-2  ring-offset-2 relative overflow-hidden;
}
