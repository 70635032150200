.focus-ring {
  @apply custom-ease focus:outline-none disabled:opacity-75 focus:ring-4 ring-offset-4  ring-blue-300  focus:rounded-lg;
}

.centre {
  @apply absolute text-center w-full;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shadow-up-lg {
  --tw-shadow: 0 -10px 15px -3px rgba(0, 0, 0, 0.1), 0 -4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}

.dropIn {
  animation: dropIn 0.25s ease-in-out;
  will-change: opacity, margin-top;
}

@keyframes dropIn {
  0% {
    opacity: 0;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}
