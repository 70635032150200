.a:not(.line, .line-grey) {
  @apply text-red-500 no-underline;

  box-shadow: 0 1px 0 rgba(239, 68, 68, 1);
}

.a:not(.line, .line-grey):focus,
.a:not(.line, .line-grey):hover {
  box-shadow: 0 2px 0 rgba(239, 68, 68, 1);
}

.a.line,
a.line-grey {
  @apply no-underline custom-ease;
}

.a.line {
  box-shadow: 0 1px 0 rgb(239, 68, 68);
}

.a.line:focus,
.a.line:hover {
  box-shadow: 0 2px 0 rgb(239, 68, 68);
}

.a.line-grey {
  box-shadow: 0 1px 0 rgb(212, 212, 216);
}

.a.line-grey:hover {
  box-shadow: 0 2px 0 rgb(212, 212, 216);
}
