
.action .loading-action {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
}

.action .loading-action .icon {
  @apply w-8 h-8;
}

.action-button {
  @apply p-1 rounded-full custom-ease relative;
}

.action-button .icon {
  @apply w-5 h-5;
}

.action-group {
  @apply flex justify-between w-full;

  grid-area: actions;
}
.action > * {
  @apply inline-flex;
}

.action .count {
  @apply text-xs min-w-[1rem];
}

.action .share,
.message:not(.main-thread, .highlighted) .action .thread {
  @apply hover:bg-gray-200 focus:bg-gray-200;
}

.action:focus .share,
.message:not(.main-thread, .highlighted) .action:focus .thread {
  @apply bg-gray-200;
}

.action .insight {
  @apply hover:bg-green-200 focus:bg-green-200;
}

.action:focus .insight {
  @apply bg-green-200;
}

.action .fresh {
  @apply hover:bg-amber-200 focus:bg-amber-200;
}
.action:focus .fresh {
  @apply bg-amber-200;
}

.action .insighted {
  @apply text-green-500;
}

.action .freshed {
  @apply text-amber-500;
}

.action .like {
  @apply hover:bg-red-200 focus:bg-red-200;
}

.action:focus .like {
  @apply bg-red-200;
}

.action .liked {
  @apply text-red-500;
}