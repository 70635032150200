.room {
  @apply bg-gray-100 place-items-stretch place-content-stretch z-50 w-full md:w-2/6 md:right-10 border custom-ease rounded-tl-xl rounded-tr-xl overflow-hidden;
}

.room-chat-min {
  @apply bottom-0 fixed;
}

.room-chat-expanded {
  @apply shadow-lg relative md:fixed h-full md:h-auto md:bottom-0;

  grid-template-columns: 100%;
  grid-template-rows: 1fr 4fr auto;
  gap: 0px 0px;
  grid-template-areas:
    '.'
    '.'
    '.';
}

.room-chat-expanded .content {
  @apply max-h-[45vh] md:max-h-[50vh];
}

/* ROOM PAGE */

.room-page {
  @apply w-full mx-auto text-black grid;

  padding: 0 !important;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: auto 2fr auto;
  grid-template-areas:
    'header'
    'content'
    'footer';
}

.room-stream {
  @apply w-full;
}

.room-stream > section {
  @apply lg:sticky lg:bottom-0 w-full;
}

.player-options .recording {
  @apply border-l border-r lg:border;
}

.player-content {
  @apply flex flex-col space-y-8 p-4 w-11/12 lg:w-full mx-auto bg-white border-l border-r border-t rounded-tl-lg rounded-tr-lg overflow-y-scroll;
}

.player-actions {
  @apply bg-white w-full px-8 py-4 border-t lg:border md:rounded;
}

.room-page .header {
  @apply bg-white lg:max-w-screen-lg;
  grid-area: header;
}

.room-page .room-details {
  @apply flex items-center -mx-2 custom-ease;
}

.room-page .room-details .back-wrapper {
  @apply px-2 flex items-center;
}

.room-page .room-details .details-wrapper {
  @apply focus:outline-none px-2 flex items-center focus:bg-gray-100;
}

.room-details .details-wrapper .room-entity {
  @apply w-8 h-10 border-2 rounded relative overflow-hidden;
}

.details-wrapper .room-entity .cover {
  @apply bg-gray-300 w-full h-full object-cover;
}

.room-page .room-options .menu-container {
  @apply relative;
}

.room-page .menu-container > .wrapper {
  @apply focus:outline-none rounded-full hover:bg-gray-200 focus:bg-gray-200;
}

.room-page .back {
  @apply px-4 pt-4;
}

.room-page .header:not(.msg-header) {
  @apply fixed z-10 border-b;
}

.room-page .header.space {
  @apply lg:top-36;
}

.room-page .room-options {
  @apply flex flex-1 items-center justify-end;
}

.room-page .room-options:not(.open) {
  @apply space-x-8;
}

.room-page .room-options > .wrapper {
  @apply rounded-full bg-gray-200 hover:bg-gray-300 focus:bg-gray-300 ring-red-500 focus:outline-none focus:ring-2 custom-ease;
}

.filter {
  @apply custom-ease flex items-center;
}

.filter > .f-container {
  @apply w-full flex justify-center;
}

.filter .f-container > .wrapper {
  @apply flex items-center w-full;
}

.filter:not(.closed) .f-container > .wrapper {
  @apply -mx-2;
}

.filter:not(.closed) .f-container > .wrapper > * {
  @apply px-2;
}

.filter.open {
  @apply w-full px-2;
}

.filter.open > .f-container {
  @apply ring-1 ring-gray-400 lg:border-gray-300 custom-ease rounded focus-within:ring-blue-400 focus-within:ring-2;
}

.filter.closed {
  @apply justify-center;
}

.filter .btn-icon:not(.filter-search, .filter-close) {
  @apply rounded-full hover:bg-gray-600 focus:bg-gray-600 lg:hover:bg-black lg:focus:bg-black hover:text-white lg:focus:text-white;
}

.filter-close,
.filter-search {
  @apply focus:bg-gray-200 hover:bg-gray-200;
}
.filter-close .icon,
.filter-search .icon {
  @apply w-6 h-6;
}

.filter-search-open {
  @apply text-gray-500 focus:bg-opacity-0 hover:bg-opacity-0;
}

.filter-search-input {
  @apply focus:outline-none rounded-none;
}

.filter-search-input:not(.room-search) {
  @apply bg-gray-900 md:bg-opacity-0;
}

.filter-search-input.open {
  @apply w-full py-2;
}

.filter-search-input.closed {
  @apply w-0 h-0;
}
.filter-search-input[type='search']::-webkit-search-decoration,
.filter-search-input[type='search']::-webkit-search-cancel-button,
.filter-search-input[type='search']::-webkit-search-results-button,
.filter-search-input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.room-page .content {
  @apply custom-ease bg-white;
  grid-area: content;
}

.room-page .footer {
  @apply fixed w-full z-20;
  grid-area: footer;
}

.footer .msg-input {
  @apply w-full p-3 bg-gray-200;
}

.footer .new-message {
  @apply mx-auto flex place-content-center py-2 px-4 mb-2 bg-blue-500 text-white rounded-full;
}

.chat-discussion {
  @apply rounded-lg bg-gray-900 p-2 flex place-items-center justify-between text-white text-left cursor-pointer;
}

.player-content .peer-list {
  @apply max-w-screen-lg flex flex-wrap justify-evenly -mx-2 overflow-y-scroll bg-gray-100 rounded-lg ring-1 ring-gray-200;
  max-height: 20vh;
}

.player-content .peer-list > * {
  @apply p-2;
}
