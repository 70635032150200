/* Base */
@tailwind base;

@layer base {
  @import "base";
  @import "nprogress";
}

/* Components */
@tailwind components;

@layer components {
  @import "components";
}

/* Utilities */
@tailwind utilities;

@layer utilities {
  @import "utilities";
}
