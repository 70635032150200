.nav {
  @apply flex items-center lg:bg-white lg:border-b lg:top-0 bottom-0 sticky z-50 bg-gray-900 text-white lg:text-black;

  grid-area: nav;
  padding-bottom: calc(env(safe-area-inset-bottom, 1rem) - 1rem);
}

@screen lg {
  .nav {
    @apply pb-0;
  }
}

.nav > .container {
  @apply lg:max-w-screen-xl lg:px-4 mx-auto flex lg:sticky justify-around lg:justify-between items-center lg:py-2;
}

.nav .content {
  @apply flex flex-wrap items-center justify-between w-full max-w-screen-md lg:max-w-screen-lg mx-auto px-4 lg:px-8 xl:px-0;
}

.nav .logo,
.nav .links {
  @apply transform transition-all duration-200 ease-in-out;
}

.nav .logo {
  @apply w-full h-full;
}

.nav .home-link {
  @apply relative overflow-hidden;
}

.nav .search {
  @apply order-last lg:order-2 w-full lg:w-7/12 pb-4 lg:py-3 lg:mt-0 mx-auto rounded;
}

.nav .searching,
.nav .links.searching {
  @apply hidden lg:block;
}

.nav .links {
  @apply order-2 lg:order-last py-4 lg:py-0 flex items-center;
}

.links .auth {
  @apply flex -mx-4;
}

.spinner {
  @apply animate-spin w-5 h-5;
}

.nav-search {
  @apply bg-gray-900 bottom-20 sticky lg:bg-white lg:block z-50;
  grid-area: search;
}

.navOption {
  @apply flex justify-center lg:justify-start items-center w-10 h-10 lg:h-[max-content] focus:bg-gray-700 lg:focus:bg-red-200 ring-red-500 lg:focus:ring-0 lg:hover:ring-0  lg:w-[max-content] lg:py-2 lg:px-4 cursor-pointer lg:hover:bg-red-200 rounded-full focus:outline-none lg:border-white lg:border-2 lg:hover:text-red-900 lg:focus:text-red-900 lg:focus:border-red-500 relative;
}

.navOption > .icon {
  @apply w-6 h-6 lg:mr-2;
}

.navOption .nav-notification {
  @apply absolute -top-2 -right-4 lg:top-0 lg:left-7 rounded-full bg-red-500 text-white text-sm w-5 h-5 flex items-center justify-center mr-2;
}

.navOption > span {
  @apply font-bold text-xl hidden lg:block;
}

.navOption--active {
  @apply text-white bg-gray-800 ring-2 ring-red-500 focus:bg-gray-700 lg:focus:bg-red-400 lg:ring-0 lg:border-none lg:text-red-900  lg:bg-red-200;
}

.logo-link {
  @apply hidden lg:block w-16 h-16 p-3 rounded-full focus:outline-none border-white border-2 focus:border-red-500 focus:bg-red-100 hover:bg-red-200;
}
