.form-label {
  @apply mb-2 block text-gray-800 select-none;
}
.form-input .react-time-picker__wrapper {
  @apply border-none;
}
.form-input:not(.filter-search-input, .input),
.form-textarea,
.form-select {
  @apply leading-normal block w-full text-gray-800  font-sans rounded text-left appearance-none relative focus:outline-none focus:ring-2 focus:ring-blue-300 placeholder-gray-600 placeholder-opacity-100 disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed custom-ease;
}

.form-select {
  @apply pr-6;

  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAABGdBTUEAALGPC/xhBQAAAQtJREFUOBG1lEEOgjAQRalbGj2OG9caOACn4ALGtfEuHACiazceR1PWOH/CNA3aMiTaBDpt/7zPdBKy7M/DCL9pGkvxxVp7KsvyJftL5rZt1865M+Ucq6pyyF3hNcI7Cuu+728QYn/JQA5yKaempxuZmQngOwEaYx55nu+1lQh8GIatMGi+01NwBcEmhxBqK4nAPZJ78K0KKFAJmR3oPp8+Iwgob0Oa6+TLoeCvRx+mTUYf/FVBGTPRwDkfLxnaSrRwcH0FWhNOmrkWYbE2XEicqgSa1J0LQ+aPCuQgZiLnwewbGuz5MGoAhcIkCQcjaTBjMgtXGURMVHC1wcQEy0J+Zlj8bKAnY1/UzDe2dbAVqfXn6wAAAABJRU5ErkJggg==');
  background-size: 0.7rem;
  background-repeat: no-repeat;
  background-position: right 0.7rem center;
}

.form-select::-ms-expand {
  @apply opacity-0;
}

.form-success {
  @apply text-gray-500 mt-2 text-sm;
}

.form-error {
  @apply text-red-500 mt-2 text-sm;
}

.form-input.error,
.form-textarea.error,
.form-select.error {
  @apply border-red-400 focus:border-red-400 focus:ring-red-400;
}

.file-input {
  @apply w-full;
}

.file-input .file-input-add {
  @apply bg-gray-200;
}

.file-input-add {
  @apply p-2 rounded-full hover:bg-gray-500 focus:ring-2 ring-offset-2 focus:outline-none focus:bg-gray-400 hover:bg-opacity-40 focus:bg-opacity-40 disabled:hover:bg-gray-200;
}
