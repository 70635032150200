footer:not(.react_tinylink_footer) {
  @apply p-4 bg-warmGray-900 z-10;
}

footer:not(.react_tinylink_footer) .wrapper {
  @apply flex flex-wrap items-center place-content-between md:place-content-around text-sm text-warmGray-300;

  padding-bottom: env(safe-area-inset-bottom, 2rem);
}

footer:not(.react_tinylink_footer) a {
  @apply hover:underline;
}
