.user-profile {
  @apply hover:bg-gray-100 p-2 custom-ease border-t first:border-t-0;
}

.user-profile > .wrapper {
  @apply grid rounded-lg ring-red-500 ring-offset-4 focus:ring-2;

  grid-template-columns: max(48px) 1fr;
  grid-template-rows: max-content;
  gap: 0em 1em;
  grid-template-areas: 'profile details';
}

.user-profile .profile {
  @apply relative flex items-center;

  grid-area: profile;
}

.user-profile .profile > div {
  @apply rounded-full custom-ease ring-1 ring-gray-300;
}

.user-profile .profile:focus > div,
.user-profile .profile:hover > div {
  @apply ring-2 ring-red-500 ring-offset-2;
}

.user-profile .details {
  @apply flex items-center justify-between;

  grid-area: details;
}

.user-profile .details .icon {
  @apply w-8 h-8 text-gray-300 custom-ease;
}

.user-profile h2 {
  @apply line-clamp-1;
}

.user-profile h2 span {
  @apply text-red-900;
}

.user-profile p {
  @apply line-clamp-2;
}

:root {
  --avatar-size: 3rem;
  --dot-size: 30px;
}

.avatarAction {
  position: absolute;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--avatar-size);
  height: var(--avatar-size);
  width: var(--avatar-size);
  font-size: 28px;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}

.avatarAction:hover {
  opacity: 1;
}

.dot {
  position: absolute;
  background: white;
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: var(--dot-size);
  right: calc(var(--dot-size) / -2);
  bottom: calc(var(--dot-size) / -4);
  color: var(--dark-bg);
  text-indent: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.User {
  @apply flex items-center justify-center flex-col text-xs space-y-1;
}

.User .name {
  @apply line-clamp-1 text-center;
  color: var(--active-color);
  max-width: 3em;
}

.avatar {
  @apply relative flex items-center justify-center rounded-full ring-red-500 ring-offset-2 focus:ring-2 hover:ring-2;
  height: var(--avatar-size);
  width: var(--avatar-size);
  user-select: none;
  color: var(--active-color);
}

.avatar::after {
  content: '';
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0px 0px 3px rgb(240 68 68 / 100%);
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.25s ease-in-out;
}

.avatar.speaking::after {
  opacity: 1;
  transform: scale(1);
}

.avatar span {
  font-size: calc(var(--avatar-size) / 2); /* 50% of parent */
  font-weight: 600;
}
