.trending > .content,
.shelf > .content {
  @apply w-full h-full pt-4 md:pb-4 xl:px-0 bg-gray-900 text-white overflow-x-hidden space-y-2;
}

.trending > .content > h2,
.shelf > .content > h2 {
  @apply mx-auto text-2xl font-bold pl-4 lg:pl-0;
}

.trending > .content > h2 {
  @apply max-w-screen-xl md:px-4;
}

.shelf > .content > h2 {
  @apply max-w-screen-lg md:px-4;
}

.trending .entities,
.shelf .entities {
  @apply flex overflow-x-scroll lg:pl-8 py-4;

  scroll-snap-type: mandatory;
  scroll-snap-points-x: repeat(300px);
  scroll-snap-type: x mandatory;
}

.hero {
  @apply w-full relative flex flex-wrap flex-row;

  place-content: flex-end;
}

.hero h1 {
  @apply text-2xl lg:text-4xl font-bold;
}

.hero .text {
  @apply text-xl;
}

.overlay {
  @apply w-full h-full absolute bg-black bg-opacity-50;
}
